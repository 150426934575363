import { RichText } from "prismic-reactjs";
import React from "react";
import { Image } from "react-bootstrap";
import "../../styles/components/Slices/style.scss";

const ImageSection = ({ slice }) => {
  
  return (
    <div className="image-section">
      <div className="subtitle">
        <RichText render={slice.primary.subtitle} />
      </div>
      <div className="top-text">
        <RichText render={slice.primary.top_text} />
      </div>
      {slice.primary.image.url && <Image
        src={slice.primary.image.url}
        className="img-fluid"
      />}
      <div className="bottom-text">
        <RichText render={slice.primary.bottom_text} />
      </div>
    </div>
  );
};

export default ImageSection;
