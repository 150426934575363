import { RichText } from "prismic-reactjs";
import React from "react";
import ReactPlayer from "react-player";
import "../../styles/components/Slices/style.scss";

const VideoSection = ({ slice }) => {
  return (
    <div className="video-section">
      <div className="subtitle">
        <RichText render={slice.primary.subtitle} />
      </div>
      <div className="top-text">
        <RichText render={slice.primary.top_text} />
      </div>
      <ReactPlayer
        className="blog-details-video"
        url={slice.primary.video.url}
        light={slice.primary.video_thumbnail.url}
        // playIcon={<Image src="/assets/images/play.png" alt="" fluid />}
        playing
        controls
      />
      <div className="bottom-text">
        <RichText render={slice.primary.bottom_text} />
      </div>
    </div>
  );
};

export default VideoSection;
