import { RichText } from "prismic-reactjs";
import React from "react";
import "../../styles/components/Slices/style.scss";

const TextSection = ({ slice }) => {
  return (
    <div className="text-section">
      <div className="subtitle">
        <RichText render={slice.primary.subtitle} />
      </div>
      <div className="text">
        <RichText render={slice.primary.text} />
      </div>
    </div>
  );
};

export default TextSection;
