import React from "react";
import { ImageSection, TextSection, VideoSection } from "../Slices";

const SliceZone = ({ sliceZone }) => {
  return (
    <>
      {sliceZone?.map((slice, index) => {
        switch (slice.slice_type) {
          case "text":
            return <TextSection slice={slice} key={`slice-${index}`} />;
          case "image":
            return <ImageSection slice={slice} key={`slice-${index}`} />;
          case "video":
            return <VideoSection slice={slice} key={`slice-${index}`} />;
          default:
            return null;
        }
      })}
    </>
  );
};

export default SliceZone;
